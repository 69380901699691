@import 'styles/mixins.scss';

.container{
	// @include display-flex-start;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0 auto;
    width: 1084px;
    // max-height: 550px;
	height: 533px;
	position: relative;
	top: 0;
	left: 0;
    box-sizing: border-box;
    background: white;
	border: 1px solid #F8985F;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    border-radius: 19px;
    z-index: 2;
}

.sub-container{
    @include display-flex-start;
    position: relative;
}

.headseperator{
	position: absolute;
    width: 100%;
    height: 5px;
    top: 0px;
    box-sizing: border-box;
}

.top-metrics-container{
	@include display-flex-start
	// padding-right: 45px
	// margin-right: 18px
}

.game-metrics-container{
	@include display-flex;
}

.action-info{
	margin-left: 10px;
    margin-right: 20px;
}

.image-container{
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.action-info-icon{
	width: 6px;
	height: 7px;
	margin-right: 3px;
}

.title{
	top: '276px',
}

.response-container{
    width: 100%;
	height: 100%;
	box-sizing: border-box;
}

.first-half{
    background: white;
	padding: 26px 44px 44px 44px;
}

.person-name{
	width: 80%;
	height: 100%;
	padding: 0px 30px 20px 15px;
	box-sizing: border-box;
}

.response-message{
	width: 100%;
    box-sizing: border-box;
    padding: 7px 0px 10px 0;
}

.close-container{
	position: absolute;
	top: 15px;
	right: 15px;
	width: 25px;
	height: 25px;
}

.actorInfo{
	@include display-flex;	
}

.takeAction{
	width: 212px;
	height: 34px;
	margin-top: 25px;
	cursor: pointer;
}

.okButton{
	width: 212px;
	height: 34px;
	margin-top: 56px;
	cursor: pointer;
	margin-bottom: 36px;
}

.eventInfo{
	margin-top:5px;
}

.cards{
	@include display-flex-start;
	margin-top: 10px;
}

.action-event-title{
	margin-top: 32px;
}

.action-desc{
	margin-top:25px;
	width:848px;
}

.action-response{
	margin-top:25px;
}

.visible{
	visibility: visible;
}

.hidden{
	visibility: hidden;
}

.event-metric-changes {
    max-width: 700px;
}