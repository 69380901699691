@import 'styles/mixins.scss';

.component {
    margin-left: auto;
    margin-right: auto;
    width: 1011px;
    height: 363.11px;  
}

.image{
    width: 194px;
    height: 129px;
}

.center{
    @include display-flex-center;
    width: 100%;
}

.heading{
    @include display-flex-center;
    height: 129px;
}

.title{
    background: linear-gradient(171.46deg, #FB7286 2.67%, #F79F58 100%);
	-webkit-background-clip: text;
	background-clip: text;
  	-webkit-text-fill-color: transparent;
    width: 100%;
}

.desc{
    margin-top: 10px;
    width: 100%;
    height: 77px;
}

.metrics{
    height: 65px;
    margin-top: 34.11px;
    margin-bottom: 20px;
}

.info{
    margin-left: 38px;
}

.box{
    height: auto;
    border-radius: 10px;
}

.box-title{
    @include display-flex-center;
    padding-top:23px;
    padding-bottom: 25px;
    width: 100%;
    height:40px;
}

.feedback{
    @include display-flex-center;
    width: 100%;
    height: 24px;
    margin-top: 6px;
    margin-bottom: 25px;
}

.feedback-btn{
    @include display-flex-center;
    width: 153px;
    height: 25px;
    margin-left: 5px;
    border-radius: 18px;
    border-width: 1.5px;
    border-style: solid;
    border-color: rgb(0, 215, 255);
    cursor: pointer;
}

.buttons{
    @include display-flex;
    height: 40px;
    margin-left:245px;
    padding-bottom: 50px;
}

.outer_component{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1280px;
    margin: auto;
    height: 100%;
}
