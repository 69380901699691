@import 'styles/mixins.scss';

.container{
	@include display-flex-center;
	margin: 0 auto;
    width: 1084px;
    height: 420px;
	max-height: 420px;
	// border-top-left-radius: 25px;
	// border-top-right-radius: 25px;
	box-sizing: border-box;
	position: relative;

	border: 1px solid #F8985F;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
	border-radius: 19px;
	overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
}

.headseperator{
	position: absolute;
    width: 98%;
    height: 5px;
    top: 0px;
    box-sizing: border-box;
}

.top-metrics-container{
	@include display-flex-start
	// padding-right: 45px
	// margin-right: 18px
}

.game-metrics-container{
	@include display-flex;
}

.action-info{
	margin-left: 10px;
    margin-right: 20px;
}

.action-info-icon{
	width: 6px;
	height: 7px;
	margin-right: 3px;
}

.title{
	top: '276px',
}

.response-container{
    width: 100%;
    height: 100%;
    @include display-flex-center;
    background: white;
	box-sizing: border-box;
	padding: 26px 45px 40px 45px;
	border-radius: 19px;
}

.person-name{
	width: 80%;
	height: 100%;
	padding: 0px 30px 20px 15px;
	box-sizing: border-box;
}

.response-message{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 15px;
	box-sizing: border-box;
	padding: 7px 0px 10px 0;
}

.close-container{
	position: absolute;
	top: 15px;
	right: 15px;
	width: 25px;
	height: 25px;
}

.actorInfo{
	@include display-flex;	
}

.okButton{
	width: 212px;
	height: 34px;
	margin-top: 15px;
	cursor: pointer;
}

.eventInfo{
	margin-top:5px;
}