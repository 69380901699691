@import 'styles/mixins.scss';

.leaderboard-popup-container{
	width: 1084px;
    height: 511px;
    z-index: 2;
    box-sizing: border-box;
    @include display-flex;
}

.header-container{
	@include display-flex-center;
	width: 100%;
	height: 46px;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	z-index: 2;
	position: relative;
	top: 0;
	left: 0;
}

.close-icon{
    margin-left: -6px;
	cursor: pointer;
}

.content{
	width: 100%;
	height: 440px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	position: relative;
	top: 0;
	left: 0;
}

.table-header-container{
	width: 100%;
	text-transform: uppercase;
	// @include display-flex-start
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	padding-top: 10px;
}

.position{
	min-width: 100px;
	box-sizing: border-box;
}

.username{    
    min-width: 360px;
	box-sizing: border-box;
	padding-left: 43px;
}

.user{
	padding-bottom: 5px;
}

.days-remaining{
	min-width: 172px;
	box-sizing: border-box;
}

.happiness{
	min-width: 162px;
	box-sizing: border-box;
}

.revenue{
	min-width: 122px;
	box-sizing: border-box;
}

.position-header{
	padding-left: 43px;
}

.position-body{
	@include display-flex-center;
}

.table-body-container{
	height: calc(100% - 25px);
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
}

.table-row{
	@include display-flex-horizontal-start;
	padding: 12px 0;
}

.seperation-line{
	width: 826px;
	box-sizing: border-box;
	margin: 0 36px;
	border: 1px solid #407EA6;
	opacity: 0.1;
}

.background-image{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.table-container{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: calc(100% - 47px);
	margin-top: 47px;
}
.buttonContainer{
	@include display-flex-end;
	margin-right: 25px;
}
.button{
	@include display-flex-center;
    width: 130px;
    height: 25px;
	box-sizing: border-box;
	border-radius: 18px;
	margin-top: 10px;
	cursor: pointer;
    color: #00D7FF;
	// background: linear-gradient(102.64deg, #00D7FF 4.65%, #7DEEB9 97.99%);
	// -webkit-background-clip: text;
	// background-clip: text;
  	// -webkit-text-fill-color: transparent;
}

.headerColor{
	background: linear-gradient(171.46deg, #FB7286 2.67%, #F79F58 100%);
	-webkit-background-clip: text;
	background-clip: text;
  	-webkit-text-fill-color: transparent;
}

.image-container{
	@include display-flex-end;
	margin-right: 7.8px;
	width: 174.2px;
	height: 184.98px;
	margin-left: 890px;
    margin-top: 220px;
}

.userimage{
	padding-left: 43px;
	width: 24px;
	height: 24px;
}

.user{
	min-width: 422px;
    box-sizing: border-box;
    padding-left: 43px;
    min-width: 360px;
}

.heading-icon{
	margin-right: 10px
}
