@import 'styles/mixins.scss';

.intro-component {
    width: 100%;
    height: calc(100% - 50px);
    .container {
        width: 100%;
        height: calc(100% - 72px);
        overflow-y: auto;
        .intro-body {
            height: calc(100% - 52px);
            max-width: 800px;
            margin: 26px auto;
        }
    }
    .intro-footer {
        @include display-flex-center;
        width: 100%;
        height: 72px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
    }
}