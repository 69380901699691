@import 'styles/mixins.scss';

.container{
	@include display-flex-center;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 25px;
    box-sizing: border-box;
}

.headseperator{
	position: absolute;
    width: 1208px;
    height: 5px;
    top: 1px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-sizing: border-box;
}

.top-metrics-container{
	@include display-flex-start
	// padding-right: 45px
	// margin-right: 18px
}

.image-container{
	// @include display-flex-center;	
	width: 129px;
	height: 136.98px;
	// padding: 20px 0
	box-sizing: border-box;
	bottom: 20px;
    right: 20px;
    position: absolute;
}

.imageClose1{
    height: 43px;
    position: absolute;
    right: 0px;
    top: -47px;
    cursor: pointer;
}   

.imageClose2{
    height: 43px;
    position: absolute;
    right: 0px;
    top: -47px;
    cursor: pointer;
}

.response-container{
	width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.person-name{
	width: 80%;
	height: 100%;
	padding: 0px 30px 20px 15px;
	box-sizing: border-box;
}

.close-container{
	position: absolute;
	top: 15px;
	right: 15px;
	width: 25px;
	height: 25px;
}

.delayed-impact{
	position: relative;
	text-align: center;
}

.centered{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 97px;
}

.action-response-icon{
	margin: 0
}

.button-container {
    @include display-flex-start;
    margin-top: 25px
}