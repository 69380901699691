@import 'styles/mixins.scss';

.container {
    width: 100%;
    height: 100%;
    position: relative;
}

.actor-card-step-container {
    position: absolute;
    display: flex;
    top: 15px;
}

.happiness-bar-container {
    position: absolute;
    display: flex;
    top: 132px;
}

.unexplored-card-container {
    position: absolute;
    display: flex;
    top: 105px;
    left: -15px;
}

.small-arrow {
    min-width: 65px;
    width: 65px;
    height: 6px;
    img {
        width: 100%;
        height: 100%;
    }
}

.large-arrow {
    min-width: 80px;
    width: 80px;
    height: 7px;
    img {
        width: 100%;
        height: 100%;
    }
}

.text-container {
    margin-left: 26px;
    .title {
        margin-bottom: 8px;
    }
}

.buttons {
    display: flex;
    margin-top: 40px;
    position: absolute;
    top: 174px;
    left: 85px;
}

.click-step-container {
    @include display-flex-center;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    margin-left: 35px;
    border-left: 1px dashed black;
    position: relative;
}