@import 'styles/mixins.scss';

.info-container{
    width: 12px;
    height: 12px;
    position: relative;
}

.info-icon{
    width: 12px;
    height: 12px;
    cursor: pointer;
    @include display-flex-center;
}
.tooltip-container-right{
    width: 225px;
    position: absolute;
    top: 1px;
    left: 12px;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.tooltip-container-left{
    width: 225px;
    position: absolute;
    top: -5px;
    left: -250px;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.tooltip-container-top{
	width: 225px;
	position: absolute;
	bottom: 100%;
	left: calc(50% - 125px);
	z-index: 11;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.tooltip-container-bottom{
	width: 225px;
	position: absolute;
	top: 18px;
	left: -116px;
	z-index: 11;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.tooltip-arrow-left-right{
	width: 0;
	height: 0;
	margin-top: 8px;
}
.tooltip-arrow-top-bottom{
	width: 0;
    height: 0;
}

.tooltip-content{
    box-sizing: border-box;
    padding: 7px;
    width: calc(100% - 7px);
    text-align: left;
}