@import 'styles/mixins.scss';

.container {
    @include display-flex;
    padding: 22px 30px;
    box-sizing: border-box;
    border: 1px dashed red;
    background-color: white;
    margin-left: 30px;
    width: 400px;
}

.objectives-container {
    @include display-flex-center;
    padding: 22px 30px;
    box-sizing: border-box;
    background-color: white;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 490px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 320px;
}

.conditions-container {
    width: 100%;
    .list-container {
        margin-top: 12px;
        .list-item-container {
            @include display-flex;
            justify-content: flex-start;
            align-items: baseline;
            margin-left: 10px;
            margin-top: 10px;
            .list-item-bullet {
                width: 6px;
                min-width: 6px;
                height: 6px;
                margin-right: 13px;
                border-radius: 50%;
            }
        }
    }
}
 
.text-container {
    width: 100%;
    .list-container {
        margin-top: 12px;
    }
}

.obj-item-container {
    @include display-flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-left: 10px;
    margin-top: 10px;
}

.list-icon-container { 
    width: 37px;
}