.step-1-container {
    width: 275px;
    display: flex;
    box-sizing: border-box;
    margin: -35px 0 0 110px;
}

.step-1-container-text-box {
    margin-left:10px;
    text-align: initial;
    padding-bottom: 0 !important;
    .step-1-container-header {
        font-family: 'Nunito';
        font-weight: bold;
        font-size: 16px;
        color: black;
        opacity: 0.8;
    }
    .step-1-container-text {
        margin-top: 10px;
        font-family: 'Nunito';
        font-size: 14px;
        color: #000000;
        opacity: 0.7;
    }
    .step-1-container-buttons {
        display: flex;
        margin-top: 25px;
    }
    .step-1-container-img {
        margin: 8px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
            margin-right: 10px;
        }
    }
}

.second-header-step {
    margin: -35px 0 0 54px !important;
}

.third-header-step {
    margin: -35px 0 0 52px !important;
}

.fourth-header-step {
    margin: -35px 0 0 52px !important;
}

.fifth-header-step {
    width: unset;
    height: 320px;
    margin: 10px 0 0 -30px !important
}

.fifth-header-box {
    margin: -35px 0 0 10px;
    padding-left: 40px;
    padding-top: 60px;
    // box-sizing: border-box;
    border-left: 1px dotted black;
}

// .vertical-dotted-line {
//     max-height: 100%;
// }

.action-step-1 {
    min-width: 550px;
    margin: -53px 0 0 -125px;
}

.action-step-2 {
    min-width: 800px;
    margin: -53px 0 0 -235px;
}

.action-step-3 {
    width: 250px;
    border-right: 1px dashed black;
    padding-right: 100px;
    box-sizing: border-box;
}

// .button {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 25px;
//     width: 85px;
//     box-sizing: border-box;
//     border-radius: 25px;
//     font-family: 'Nunito';
//     font-weight: 900;
//     font-size: 12px;
//     text-transform: uppercase;
//     cursor: pointer;
// }

.next-button {
    background: linear-gradient(127.33deg, #00D7FF 4.65%, #7DEEB9 97.99%);
    color: #FFFFFF;
}

.next-btn-margin {
    margin-left: 10px;
    min-width: 103px;
}

.previous-button {
    border: 1px solid #00D7FF;
    background: white;
    color: #00D7FF;
}

.button-text {
    margin: auto
}

.step-2-container-img-box {
    width: 36px;
    min-width: 36px;;
}

.step-2-container-text-box{
    padding-right: 10px;
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important
}

.step-2-container-text {
    font-size: 16px;
    line-height: 25px;
    color: #031A20;
    opacity: 0.8;
}

.step-3-container {
    min-width: 695px;
    display: flex;
    box-sizing: border-box;
    text-align: left;
    padding-right: 25px;
    font-size: 16px;
    line-height: 25px;
}

.step-4-container {
    max-width: 400px
}