@import 'styles/mixins.scss';

.outside-container {
    width: 83px;
    margin: 0 20px;
    padding: 4px;
    border-radius: 59px;
    .container {
        @include display-flex-center;
        padding: 8px 4px 5px;
        border-radius: 59px;
    }
}