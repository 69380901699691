@import 'styles/mixins.scss';

.body{
	width: 46.12%;
	height: 290px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.heading{
	margin-top: 15px;
	margin-left: 39.7px;
}

.desc{
	margin-top: 4px;
	margin-left: 39.7px;
	margin-right: 10px;
}