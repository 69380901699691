@import 'styles/mixins.scss';

.container{
	width:84.68%;
	height: calc(100% - 130px);
	max-height: 819px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 2;
}

.head{
	@include display-flex-center;
	width: 100%;
	height: 46px;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	z-index: 2;
	position: relative;
	top: 0;
	left: 0;
}

.title{
	@include display-flex-center;
	width: 100%;
	z-index: 1;
}

.close-icon{
	cursor: pointer;
	margin-right: -44px;
    margin-bottom: 34px;
}

.quarters{
	@include display-flex;
	width: 100%;
	height: 46px;
	div:first-child{
		margin-left:4%;
	}
}

.lineGraph{
	@include display-flex-center;
	margin-top: 17.33px;
	div:first-child{
		margin-right:20px;
	}
}

.action{
	width: calc(92.24% + 20px);
	height: 480px;
	margin-left: calc(3.88% - 10px);
	margin-top:17.33px;
	margin-bottom: 17.33px;
}

.scrollbar{
	overflow: auto;
	height: calc(100% - 92px);
	max-height: 727px;
}

.quarter{
	margin-top: 16px;
	margin-left: 37px;
	text-transform: uppercase;
	height: 12px;
	cursor: pointer;
}

.line{
	margin-top: 8px;
	height: 5px;
	border-radius: 5px;
}

.heading-icon{
	margin-right: 10px
}