@import 'styles/mixins.scss';

.containerhead{
	@include display-flex;
	width: 1100px;
	height: 518px;
    margin-top: 50px;
    z-index: 2;
}
.close-image{
	margin-top: -20px;
	cursor: pointer;
}
