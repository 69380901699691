@import 'styles/mixins.scss';

.complete-container{
	width: 100%;
	height: calc(100% - 50px);
	position: absolute;
	top: 50px;
	left: 0;
	overflow: hidden;
	box-sizing: border-box;
    z-index: 150;
}

.background-container {
    // opacity: 1;
    opacity: 0.9;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
}

.bottom-container {
	display: flex;
	align-items: flex-end;
    justify-content: center;
}

.bottom-container-component{
    width: 100%;
}

.right-container{
	display: flex;
	justify-content: flex-end;
    align-items: flex-start;
}

.center-container{
	display: flex;
	justify-content: center;
    align-items: center;
}

.right-container-component{
	height: 100%;
	min-width: 500px;
	display: flex;
	align-items: flex-end;
    justify-content: flex-start;
}