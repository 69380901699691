@import 'styles/mixins.scss';

.container {
    width: 180px;
    height: 100%;
    padding: 7px 0;
    margin: 0 18px;
    box-sizing: border-box;
    position: relative;
    .text {
        @include display-flex-space-between;
        align-items: center;
        .metric-name {
            @include display-flex-center;
            img {
                margin-right: 3px;
            }
        }
    }
    .graph {
        width: 100%;
        height: 12px;
        border-radius: 10px;
    }
}

.value-changes {
    position: absolute;
    width: 100px;
    height: 28px;
    left: 50px;
    top: 44px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    z-index: 200;
    @include display-flex-center;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;

    .day-icon {
        margin: 0 4px;
    }
}

.header-space{
    margin-right: 4px;
}

.revenue-value{
    @include display-flex-center
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}