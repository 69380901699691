@import 'styles/mixins.scss';

.containerhead {
    @include display-flex-column;
    position: relative;
    width: 69%;
    height: 100%;
    margin-left: 6px;
    background: #FFFFFF;
    border: 1px solid #F8985F;
    padding: 20px 30px;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    border-radius: 19px;
    overflow: auto;
}
.actorDescComponent {
    width: 100%;
    min-height: calc(50% - 50px);
    height: calc(50% - 50px);
    overflow: hidden;
}
.actorDescription {
    opacity: 0.8;
    margin-top: 5px;
    align-items: center;
}
.hr-line {
    width: 100%;
    height: 2px;
    min-height: 2px;
    margin-top: 10px;
}
.actorGraphComponent {
    margin-top: 15px;
    width: 100%;
}
.bambaGraphAndTableContainer {
    width: 100%;
    height: 200px;
    padding-top: 10px;
    box-sizing: border-box;
    @include display-flex-space-between
}
.actorGraph {
    width: 326px;
    height: 160px;
}
.bamba-categories-values {
    width: 320px;
    height: 100%;
    .table-header {
        @include display-flex;
        align-items: center;
        width: calc(100% - 30px);
        height: 40px;
        margin-left: 30px;
        margin-bottom: 20px;
    }
    .bamba-values {
        @include display-flex;
        align-items: center;
        width: calc(100% - 30px);
        margin-left: 30px;
        margin-bottom:  20px
    }
    .table-values {
        max-width: 90px;
        width: 90px;
        text-align: center;
    }
    .info-text {
        @include display-flex-center;
        width: calc(100% - 30px);
        margin-left: 30px;
    }
}
.actor-graph-container {
    display: flex;
    flex-direction: column;
}
.graph-labels  {
    display: flex;
    flex-direction: row;
    justify-content: space-around;;
    align-items: center;
}
.current-label {
    width: 8px;
    height: 8px;
    background: linear-gradient(180deg, #35E794 9.36%, #C6FBE2 97.77%);
    opacity: 0.5;
    border: 1.26121px solid #32BD9B;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
}
.initial-label {
    width: 8px;
    height: 8px;
    background: linear-gradient(0deg, #A04EB5, #A04EB5);
    border: 1.26121px solid rgba(160, 78, 181, 0.7);
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
    opacity: 0.5;
}
.current {
    display: flex;
    align-items: center;
}
.initial {
    display: flex;
    align-items: center;
}
.current-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-transform: capitalize;
    color: #000000;
    opacity: 0.5;
}
.bamba-graph-labels {
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    margin-right: -30px;
}
.bamba-initial {
    width: 10px;
    height: 10px;
    background: linear-gradient(0deg, #A04EB5, #A04EB5);
    opacity: 0.5;
    border: 1.26121px solid #32BD9B;
    box-sizing: border-box;
    border-radius: 12px;
    
}
.bamba-current {
    width: 10px;
    height: 10px;
    background: linear-gradient(180deg, #35E794 9.36%, #C6FBE2 97.77%);
    opacity: 0.5;
    border: 1.26121px solid #32BD9B;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 32px;
}
.bamba-scales {
    display: flex;
    justify-content: flex-end;
    margin: 8px 0;
}

.bamba-scale-text{
    font-size: 10px;
    line-height: 150%;
    margin-right: 7px;
    margin-top: 3px;
}

.bamba-scale-text1{
    font-size: 10px;
    line-height: 150%;
    margin-left: 7px;
    margin-top: 3px;
}


.subdomain-tables {
    width: 100%;
}

.custom-classname.react-toggle--checked .react-toggle-track {
    background: #E3CAE9;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 20px;
  }

.bamba-title{
    @include display-flex;
}

.tooltip{
	margin-left: 3px;
    margin-bottom: 9px;
}

.current-text1{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-transform: capitalize;
    color: #000000;
    opacity: 0.5;
    margin-left: -63px;
    width: 106px;
}

.current-text2{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-transform: capitalize;
    color: #000000;
    opacity: 0.5;
    margin-left: -64px;
    width: 106px;
}