@import 'styles/mixins.scss';

.container {
    width: 100%;
    margin-bottom: 10px;
    .header {
        @include display-flex-center;
        width: 100%;
        height: 32px;
    }
    .domain-list {
        @include display-flex-center;
        width: 100%;
        height: 30px;
    }
    .subdomain-list {
        @include display-flex-center;
        width: 100%;
        height: 50px;
    }
}

.domain {
    width: 33%;
    height: 100%;
    @include display-flex-center;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.subdomain {
    width: 11%;
    height: 100%;
    @include display-flex-center;
    text-align: center;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.subdomain-change {
    @include display-flex-center;
    width: 100%;
}

.offset-circle{
    @include display-flex-center;
	border-radius: 12px;
	box-sizing: border-box;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 4px;
}