@import 'styles/mixins.scss';

.container{
	width: 1084px;
	height: 511px;
    box-sizing: border-box;
    @include display-flex;
    z-index: 2;
}

.header{
	height: 46px;
	display: flex;
	justify-content: center;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
}

.headerlabel{
	height: 36px;
	margin-top:6px;
}

.list{
	height: 100%;
	margin-left: 104px;
	.row:last-child{
		margin-bottom: 57px;
	}
}
.history{
	position: relative;
	min-height: 100px;
	margin-top: 25px;
}
.historyArrow{
	width: 28px;
	height: 28px;
	background: #FFFFFF;
	border-radius: 10px;
	margin-right: 12px;
}
.day{
	position: absolute;
	height: 21px;
	text-align: center;
	text-transform: capitalize;
	left: -20px;
}

.daynumber{
	position: absolute;
	height: 21px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: capitalize;
	left: -20px;
	margin-top:15px;
}
.box{
	display: flex;
	overflow: auto;
	height: 398px;
	box-sizing: border-box;
	opacity: 0.95;
}

.close{    
    margin-left: -6px;
	cursor: pointer;
}

.line{
	width: 100px;
}

.action{
	position: relative;
	
}

.each_row{
	margin-left: 24px;
	padding: 10px;
}

.eventAction{
	margin-top: 23px;
}

.eventAction_left{
	margin-left: 31px;
}

.borderBottom{
	margin-top: 15px;
    margin-left: 19px;
    border: 1px solid #FFFFFF;
}

.event{
	// min-height: 143px
	width: 667px;
	border-left: 5px solid rgba(65,210,255,0.7);
	background: white;
	box-sizing: border-box;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	position: relative;
	margin-left: 15px;
	padding: 10px;
	margin-bottom: 15px;
}

.blocker{
	// height: 143px
	width: 240px;
	border-left: 5px solid 	rgba(241,60,60, 0.7);
	background: white;
	box-sizing: border-box;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-left: 15px;
	position: relative;
	padding: 10px;
	margin-bottom: 15px;
}

.blocker-msg{
	// height: 143px
	width: 411px;
	background: white;
	box-sizing: border-box;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-left: 15px;
	padding: 10px;
	margin-bottom: 15px;
}

.header-tag{
	display: flex;
	align-items: center;
}

.spacer{
	margin-left: 20px;
	display: flex;
}
.a-name{
	margin-top: 4.51px;
}
.e-msg{
	margin-top: 5px;
}
.metrics{
	margin-top: 12px;
	width: 383px;
	height: 41px;
}

.met-box{
	display: flex;
	margin-top: 10px;
}

.met-box-eve{
	margin-top: 15px;
}

.met-container{
	margin-right: 10px;
}

.arrow-cont{
	display: flex;
}
.arrow{
	height: 10px;
	width: 10px;
	margin:  3px 2px 0px 8px;
}
.filter{
	height: 42px;
	display: flex;
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	background: white;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);

	// div:first-child{
	// 	font-family: Avenir;
	// 	font-style: normal;
	// 	font-weight: normal;
	// 	font-size: 12px;
	// 	line-height: 16px;
	// 	color: black;
	// }
}

.filter-box{
	display: block;
	cursor: pointer;
	padding: 4px 15px;
	margin-left: 14px;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(25, 134, 149, 0.35);
	border-radius: 20px;
	@include display-flex-center;
	width: 64px;
	height: 21px;
}
.delayed_style{
    position: relative;
	padding-top: 10px;
	width: 84px;
    height: 19px;
}
.delayed-impact{
	position: relative;
	text-align: center;
}
.delayed_img{
	width: 84px;
	height: 19px;
}
.centered{
	position: absolute;
    top: 14px;
    left: 5px;
}
.filter-by{
    font-family: Nunito !important;
    margin-left: 91px;
    margin-right: 30px;
}
.filter-name{
	margin-right: 5px;
}
.slected{
	color:#41D2FF;
}
.image-container{
	@include display-flex-end;
	opacity: 0.2;
	position:fixed;
    margin-left: 942px;
    margin-top: 247px;
}
.white-seperator{
	width: 770px;
	border: 1px solid #FFFFFF;
}
.heading-icon{
	height: 22px;
    margin-right: 10px;
    margin-top: 10px;
    width: 22px;
}
.reset-button {
    @include display-flex-center;
    width: 125px;
    height: 22px;
    cursor: pointer;
    border: 1.5px solid #01D7FF;
    border-radius: 20px;
    font-family: Open Sans;
    color: #01D7FF;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
}