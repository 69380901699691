@import 'styles/mixins.scss';

.container {
    @include display-flex-center;
    margin: 0 1px;
    height: 100%;
    position: relative;
    img {
        margin-right: 3px;
    }
}

.value-changes {
    position: absolute;
    width: 92px;
    height: 28px;
    left: 10px;
    top: 44px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    z-index: 200;
    @include display-flex-center;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;

    .day-icon {
        margin: 0 4px;
    }
}

.text {
    max-height: 30px;
}

.header {
    @include display-flex-start;
    img {
        width: 9px;
        height: 9px;
        margin-right: 3px;
    }
}

.value-width{
    min-width: 62px;
}

.header-space{
    margin-right: 4px;
}

.image-size{
    width: 37px;
    height: 28.05px;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}