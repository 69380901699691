@import 'styles/mixins.scss';

.container {
    width: 100%;
    height: 100%;
    padding: calc(50vh - 270px) 0;
    box-sizing: border-box;
    margin: auto;
    .welcome-icon {
        @include display-flex-center;
        width: 650px;   
        margin: 0 auto 35px;
    }
    .title {
        @include display-flex-center;
        width: 650px;   
        margin: 0 auto 12px;
    }
    .proceed-button {
        @include display-flex-center;
        width: 650px;   
        margin: 20px auto 0;
    }
    .helper-text{
        margin-top: 6px;
    }
}