@import 'styles/mixins.scss';

.container {
    width: 700px;
    z-index: 2;
}
.img-container {
    margin-bottom: 25px;
}
.title {
    margin-bottom: 12px;
}
.desc {
    @include display-flex-center;
    margin: 30px 0 6px;
}
.img-container {
	@include display-flex-center;
}