@import 'styles/mixins.scss';

.metrics-container{
	width: 100%;
    @include display-flex-start;
    margin-bottom: 20px;
}

.left-metric-container{
	min-height: 67px;
	border-radius: 18px;
	//margin: 0 8px;
	//padding: 10px 18px;
	box-sizing: border-box;
}

.left-metrics-value-change-container{
	@include display-flex-center;
	//margin-top: 5px;
}

.metrics-image{
	width: 16px;
	height: 16px;
	//margin-right: 8px;
}

.bottom-metrics-container{
	@include display-flex-start;
	//margin-top: 12px;
}

.bottom-metrics-wrapper{
	@include display-flex-start;
	border-radius: 18px;
	padding: 10px 0;
	box-sizing: border-box;
	min-height: 67px;
}
	// margin-left: 8px

.right-metric-container{
	// margin: 0 12px
	padding: 0 20px;
}

.metric-title{
	text-transform: uppercase;
	@include display-flex;
	margin-bottom: 5px;
	background: linear-gradient(171.46deg, #FB7286 2.67%, #F79F58 100%);
	-webkit-background-clip: text;
	background-clip: text;
  	-webkit-text-fill-color: transparent;
}

.days-title{
	text-transform: uppercase;
	@include display-flex-center;
	min-width: 119px;
	margin-bottom: 5px;
	background: linear-gradient(171.46deg, #FB7286 2.67%, #F79F58 100%);
	-webkit-background-clip: text;
	background-clip: text;
  	-webkit-text-fill-color: transparent;
}

.delayed-action-title{
	background: linear-gradient(171.46deg, #FB7286 2.67%, #F79F58 100%);
	-webkit-background-clip: text;
	background-clip: text;
  	-webkit-text-fill-color: transparent;
}

.delayed-action{
	@include display-flex-start;
    width: 100%;
    padding-left: 15px;
	height: 65px;
	border-radius: 10px;
	// margin-right: 33px;
	// margin-left:10px;
}

.daysFromTo{
	text-transform: uppercase;
	@include display-flex-start;
	margin-bottom: 8.04px;
}

.right-metrics-value-change-container{
	@include display-flex-start;
}

.metrics-change{
	@include display-flex-center;
	min-width: 35px;
	min-height: 17px;
    padding: 0 5px;
    margin-left: 4px;
	border-radius: 12px;
	box-sizing: border-box;
	text-align: center;
	// margin-left: 10px;
}

.iconSpace{
	margin-right: 5px;
}
.containerSpace{
	box-sizing: border-box;
	// margin-right: 33px;
}

.space{
	margin-left: 2px;
}

.mvp-title-value-container{
	@include display-flex-start;
}

.sim-mvp-progress-bar-container{
	width: 100%;
	height: 6px;
	border-radius: 2px;
	@include display-flex-start;
}

.sim-mvp-progress-bar{
	height: 6px;
	border-radius: 2px;
	display: flex;
}

.arrow{
	margin-left: 6.57px;
	margin-right: 6.57px;
}

.game-metric-container{
	// min-height: 67px;
	box-sizing: border-box;
}

.game-metrics-container{
    width: 100%;
    @include display-flex-space-between;
    align-items: center;
}

.seperator{
	height: 29px;
	border: 1px solid #F3A672;
	// margin-left: 3px;
	// margin-right: 27.66px;
}

.noChange{
	margin-bottom: 3px;
}