.container {
    width: 422px;
    min-width: 422px;
    height: 100%;
    min-height: 600px;
    max-height: 600px;
    padding: 5px 23px 14px 71px;
    box-sizing: border-box;
    .title {
        padding: 11px 26px 4px 14px;
        margin: 12px 0 15px 0;
    }
    .actors-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        .actor-tutorial-container {
            position: absolute;
            min-width: 600px;
            height: 100%;
            left: 321px;
            padding-top: 4px;
            box-sizing: border-box;
        }
    }
}