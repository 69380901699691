@import 'styles/mixins.scss';

.container {
    height: 100%;
    padding: 12px 0;
    margin: 0 6px;
    box-sizing: border-box;
    .text {
        display:flex;
        justify-content: center;
        img {
            margin-right: 3px;
        }
    }
    .graph {
        margin-left: 4px;
    }
}

.header-space{
    margin-right: 4px;
}