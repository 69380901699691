@import 'styles/mixins.scss';

.game-metrics-container{
	@include display-flex;
}

.action-info{
	margin-left: 10px;
    margin-right: 20px;
}

.action-info-icon{
	width: 6px;
	height: 7px;
	margin-right: 3px;
}