@import 'styles/mixins.scss';

.metrics-container{
	width: 100%;
}

.top-metrics-container{
	@include display-flex-start
	// padding-right: 45px
	// margin-right: 18px
}

.left-metric-container{
	min-height: 67px;
	border-radius: 18px;
	margin: 0 8px;
	padding: 10px 18px;
	box-sizing: border-box;
}

.left-metrics-value-change-container{
	@include display-flex;
}

.metrics-image{
	width: 16px;
	height: 16px;
	margin-right: 8px;
}

.bottom-metrics-container{
	@include display-flex-start;
	margin-top: 12px;
}

.bottom-metrics-wrapper{
	@include display-flex-start;
	border-radius: 18px;
	padding: 10px 0;
	box-sizing: border-box;
	min-height: 67px;
}
	// margin-left: 8px

.right-metric-container{
	// margin: 0 12px
	padding: 0 20px;
}

.metric-title{
	text-transform: uppercase;
	@include display-flex;
}

.days-title{
	text-transform: uppercase;
	@include display-flex;
	background: linear-gradient(171.46deg, #FB7286 2.67%, #F79F58 100%);
	-webkit-background-clip: text;
	background-clip: text;
  	-webkit-text-fill-color: transparent;
}

.right-metrics-value-change-container{
	@include display-flex-start;
}

.iconSpace{
	margin-right: 5px;
}
.containerSpace{
	margin-right: 17.34px;
}

.metrics-change{
	@include display-flex-center;
	min-width: 35px;
	min-height: 17px;
	padding: 0 5px;
	border-radius: 12px;
	box-sizing: border-box;
	text-align: center;
}

.space{
	margin-left: 2px;
}

.mvp-title-value-container{
	@include display-flex-start;
}

.mvp-value{
	margin-left: 12px;
}

.sim-mvp-progress-bar-container{
	width: 100%;
	height: 6px;
	border-radius: 2px;
	@include display-flex-start;
}

.sim-mvp-progress-bar{
	height: 6px;
	border-radius: 2px;
	display: flex;
}

.game-metric-container{
	// min-height: 67px;
    box-sizing: border-box;
    background: white;
}

.game-metrics-container{
	@include display-flex;
	margin-top: 12px;
}

.seperator{
	height: 19px;
	border: 1px solid #F3A672;
	margin-left: 17px;
	margin-right: 14.44px;
	margin-top: 14px;
}

.happiness{
	margin-right:22px;
}

.noChange{
	margin-bottom: 4px;
}

.delayed-action-title{
	background: linear-gradient(171.46deg, #FB7286 2.67%, #F79F58 100%);
	-webkit-background-clip: text;
	background-clip: text;
  	-webkit-text-fill-color: transparent;
}

.delayed-action{
	@include display-flex-center;
	width: 508px;
	height: 65px;
	border-radius: 10px;
}

.actor-changes-list{
	@include display-flex;
    width: 100%;
    margin: 12px 0 20px;
}