.container {
    width: 100%;
    height: 100%;
    min-height: 600px;
    max-height: 600px;
    padding: 12px 5px 20px 0;
    box-sizing: border-box;
    position: relative;
    // opacity: 0 !important;
    // -webkit-animation: containeranimation 1.5s forwards;
    // -webkit-animation-delay: 0s;
    // -moz-animation: containeranimation 1.5s forwards;
    // -moz-animation-delay: 0s;
    // animation: containeranimation 1.5s forwards;
    // animation-delay: 0s;
    .title {
        padding: 11px 26px 4px 14px;
        margin: 12px 0 15px 0;
    }
    .actions-container {
        width: 100%;
        height: 480px;
        // height: calc(100% - 63px);
        position: relative;
    }
}

// @-webkit-keyframes containeranimation {
//     100% { opacity: 1; }
// }

// @-moz-keyframes containeranimation {
//     100% { opacity: 1; }
// }

// @keyframes containeranimation {
//     100% { opacity: 1; }
// }