@import 'styles/mixins.scss';

.ext-container {
    @include display-flex-center;
    width: 100%;
}
.container {
    width: 100%;
    height: 100%;
    padding: 12px 90px 20px 90px;
    box-sizing: border-box;
}
.welcome-icon {
    @include display-flex-center;
    margin-bottom: 35px;
}
.title {
    margin-bottom: 12px;
}
.proceed-button {
    @include display-flex;
    margin-top: 20px;
}

.previous-button-container {
    @include display-flex-center;
    margin-top: 5px;
}