@import 'styles/mixins.scss';

.transcript-container {  
    width: calc(100% - 8px);
    margin: 5px 0 40px 4px;
	padding: 15px 50px 25px 50px;
    box-sizing: border-box; 
    border-radius: 8px;
    background-color: white;
}

.transcript {
    cursor: pointer;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    width: 200px;
    // @include display-flex-start;
    // width: 70%;
}

.icon {
	height: 8px;
    width: 12px;
    margin-top: -22px;
    margin-left: 10px;
}

.transcript-show {
	padding-top: 5px;
	display: block;
}

.transcript-hidden {
	padding-top: 5px;
    display: none;
}