@import 'styles/mixins.scss';
	
.email-report-container{
	width: 290px;
	height: 100%;
}

.email-heading{
	width: 100%;
	padding-bottom: 4px;
}

.email-input-container{
	@include display-flex-center;
	width: 100%;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
}

.email-input{
	width: calc(100% - 25px);
	height: 100%;
}
.email-send{
	width: 30px;
}
.email-input-element{
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
	color: white;
}
.email-input-element:focus{
	outline: none;
}
.email-status-bar{
	width: 100%;
	height: calc(100% - 36px);
	padding-top: 2px;
	box-sizing: border-box;
}