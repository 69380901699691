@import 'styles/mixins.scss';

.ext-container {   
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    .container {
        width: 100%;
        max-width: 550px;
        margin-left: 40px;
        .title {
            margin-bottom: 8px;
        }
        .action-step-desc {
            margin-bottom: 13px;
        }
        .choose-text {
            margin-bottom: 15px;
            margin-top: 65px;
        }
        .action-path {
            margin-bottom: 13px;
        }
        .action-card {
            margin-bottom: 20px;
            margin-left: -10px;
        }
        .action-desc {
            display: inline;
            width: 100%;
            .action-desc-header {
                font-weight: bold;
                margin-right: 5px;
            }
        }
        .buttons {
            @include display-flex-start;
            margin-top: 50px;
            .cancel-button {
                @include display-flex-center;
                min-width: 140px;
                height: 33px;
                border-radius: 18px;
                margin-right: 15px;
                cursor: pointer;
            }
        }
    }
}