@import 'styles/mixins.scss';

.container {
    @include display-flex-space-between;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 55px;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 10;
    position: relative;
    .helper-textbox {
        position: absolute;
        top: 110px;
        width: 100%;
        .arrow {
            width: 100%;
            @include display-flex-center;
        }
        .text {
            width: 100%;
            margin-top: 15px;
            @include display-flex-center;
        }
    }
}

.category-container {
    margin: auto 16px;
    cursor: pointer;
    // remove later
    overflow: hidden;
}

.category-image {
    // width: 15px;
    // height: 15px;
    // margin: 9px auto 6px auto;
    @include display-flex-center;
}

.category-name {
    margin: 0 auto 5.4px auto;
}