@import 'styles/mixins.scss';

.button {
    @include display-flex-center;
    min-width: 212px;
    height: 35px;
    padding: 9px 21px;
    box-sizing: border-box;
    border-radius: 25px;
    cursor: pointer;
    z-index: 5;
}