@import 'styles/mixins.scss';

.container{
	width: 540px;
	height: 336px;
    border-radius: 10px;
    z-index: 2;
}

.header-container{
	width: 540px;
	height: 166px;
	border-radius: 10px;
}

.content{
	width: 540px;
	height: 170px;
}

.info-icon{
	width: 72px;
	height: 72px;
	padding-top: 24px;
	margin-left:234px;
	margin-bottom: 25px;
}
.title{
	@include display-flex-center;
}

.desc{
	@include display-flex-center;
	height: 51px;
	margin-left: 34px;
	margin-right: 43px;
	margin-top: 20.22px;
	margin-bottom: 37px;
}

.got-it{
	@include display-flex-center;
	width: 134px;
	height: 34px;
	margin-left : 201px;
	cursor: pointer;
}