// Open Sans Font

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-ExtraBold.ttf') format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-Bold.ttf') format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-SemiBold.ttf') format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-Regular.ttf') format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-Regular.ttf') format("truetype");
	font-weight: 300;
    font-style: normal;
}

// Nunito Font

@font-face {
	font-family: 'Nunito';
	src: url('./nunito/Nunito-Regular.ttf') format("truetype");
	font-weight: 400;
    font-style: normal;
}

// Cousine font for timer

@font-face {
	font-family: 'Cousine';
	src: url('./Cousine/Cousine-Regular.ttf') format("truetype");
	font-weight: 400;
    font-style: normal;
}