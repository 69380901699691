@import 'styles/mixins.scss';

.heading{
	padding-top: 15px;
	margin-left: 39.7px;
}

.desc{
	margin-top: 4px;
	margin-left: 39.7px;
	margin-right: 10px;
}

.graph{
	@include display-flex-center;
	margin-left: 49px;
	margin-right: 49px;
	margin-bottom: 26px;
}

.first-graph{
	width: 33.33%;
}

.second-graph{
	width: 33.33%;
}

.third-graph{
	width: 33.33%;
}

.outerCircle{
	@include display-flex-center;
	width: 135px;
	height: 135px;
	border: 1px solid #9BF3CA;
	box-sizing: border-box;
	border-radius: 50%;
}

.innerCircle{
	width: 89px;
	height: 89px;
	background: linear-gradient(180deg, #35E794 0%, #C6FBE2 100%);
	opacity: 0.5;
	border-radius: 50%;
}

.orgVsInd{
	@include display-flex
}

.OrgOrIndValue{
	@include display-flex-center;
	width: 135px;
	margin-top: 29px;
	margin-bottom: 10px;
}

.OrgOrIndDesc{
	@include display-flex-center;
	width: 135px;
	margin-top: 12px;
}

.individual{
	margin-left: 16px;
}

.OrgVsIndTitle{
	@include display-flex-center;
	width: 100%;
}

.indivisualActionImage{
	margin-top:30px;
	height: 203px;
}

.individualActionBreakup{
	margin-top:40.5px;
}

.IndividualActionTitle{
	@include display-flex-center;
}

.BAMBA-title{
	@include display-flex-center;
}

.individual-breakup{
	@include display-flex-center;
	height: 232px;
}

.line{
	height: 279px;
	opacity: 0.1;
	margin-top: 33px;
	border: 1px solid #000000;
}

.bamba{
	@include display-flex-center;
	height: 218px;
	margin-top: 14px;
	margin-left: 31px;
}

.pie-height{
	height:199px;
	margin-top: 33px
}

.info-icon{
	margin-left: 5.3px;
	margin-bottom: 8px;
}

.pie-desc{
	@include display-flex-center;
	margin-top: 35px;
}

.pie-desc1{
	@include display-flex-center;
	margin-left: 13px;
    align-items: baseline;
    justify-content: baseline;
	flex-wrap: wrap;
	margin-top: 5px;
}

.pie-title{
	width: 66px;
	margin-bottom: 10px;
}

.pie-title1{
	margin-bottom: 4px;
}

.pie-org-color{
	width: 12px;
	height: 12px;
	background: #C6FBE2;
	border-radius: 50%;
	margin-right: 10px;
	margin-bottom: 45px;
}

.pie-ind-color{
	width: 12px;
	height: 12px;
	background: #32BD9B;
	border-radius: 50%;
	margin-left: 63px;
	margin-right: 10px;
	margin-bottom: 45px;
}

.pie-center{
	@include display-flex-center;
}

.pie-footer{
	@include display-flex;
	margin-right: 23px;
	margin-top: 18px;
}

.tooltip{
	margin-left: 3px;
    margin-bottom: 9px;
}