@import 'styles/mixins.scss';

.container {
    @include display-flex-space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    min-width: 1280px;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    // z-index: 100;
    .icon {
        @include display-flex-center
    }
    .metrics {
        height: 100%;
        width: 823px;
        @include display-flex;
        .cost-metrics {    
            margin-right: 1px;
            height: 100%;
            @include display-flex-center;
        }
        .vr-line {
            margin: 10px 0;
            width: 1px;
            height: calc(100% - 20px);
        }
        .game-metrics {
            height: 100%;
            @include display-flex-center;
        }
        .tutorial-step-3 {
            height: 100%;
            @include display-flex-center;
        }
    }
}