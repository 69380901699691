body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  background: white;
}

.base-app-box {
    width: 100vw;
    height: 100vh;
    min-width: 1270px;
    min-height: 600px;
    position: relative;
    overflow: hidden;
}

.app-body {
    width: 100%;
    height: calc(100% - 50px);
    background: white;
    background-repeat: no-repeat;
    background-size: cover
}

/* react-joyride container style */
.__floater {
    filter: none !important;
}

.__floater__arrow {
    visibility: hidden !important;
}

.react-joyride__overlay {
	cursor: default !important;
}

.react-joyride__tooltip {
    background-color: unset !important;
}

/* Buttons for tutorials */
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    min-width: 85px;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: 'Nunito';
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
}

.next-button {
    background: linear-gradient(127.33deg, #00D7FF 4.65%, #7DEEB9 97.99%);
    color: #FFFFFF;
}

.next-btn-margin {
    margin-left: 10px;
}

.previous-button {
    border: 1px solid #00D7FF;
    background: white;
    color: #00D7FF;
}

.button-text {
    margin: auto
}

/* React toggle button */
.react-toggle--checked .react-toggle-track {
    background-color: #A04EB5 !important;
}

.react-toggle-track {
    background-color: #DDDDDD !important;
}

/* Scrollbar styles */
::-webkit-scrollbar {
    width: 6px;
  }
  
/* Track */
::-webkit-scrollbar-track {
background: #FEF4EF;
border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #FB919E; 
opacity: 0.2;
border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #FB919E; 
}