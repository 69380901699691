@import 'styles/mixins.scss';

.dashboard-component {
    @include display-flex-center;
    width: 100%;
    // max-height: 650px;
    // margin-top: calc(50vh - 325px);
    max-width: 1280px;
    margin: auto;
    height: 100%;
    overflow-y: auto;
}