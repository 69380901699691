@import 'styles/mixins.scss';
	
.report-container{
	width: 290px;
	height: 100%;
}

.status-bar{
	width: 100%;
	height: calc(100% - 36px);
	padding-top: 2px;
	box-sizing: border-box;
}

.download-btn{
    @include display-flex-center;
    border-radius: 25px;
    height: 34px;
    margin-right: 20px;
    cursor: pointer;
}