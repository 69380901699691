@import 'styles/mixins.scss';

.container {
    width: 100%;
    height: calc(100% - 64px);
    margin-top: -42px;
    border-radius: 25px;
    padding: 55px 0 24px 0;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .title {
        @include display-flex-center;
        margin: auto;
    }
    .actions-container {
        max-width: 600px;
        height: 185px;
        margin: auto;
        padding: 12px 0 18px 0;
        position: relative;
        .tutorial-arrow {
            position: absolute;
        }
    }
    .action-text {
        width: 100%;
        @include display-flex-center;
        text-align: center;
        padding: 14px 25px;
        box-sizing: border-box;
        height: 65px;
        .action-types {
            @include display-flex-center;
            width: 100%;
            .action-type-single {
                @include display-flex-center;
                margin: 0 18px;
                img {
                    margin-right: 10px;
                }
            }
        }
        .action-desc {
            @include display-flex-horizontal-center;
            display: inline;
            width: 100%;
            .action-desc-header {
                font-weight: bold;
                margin-right: 5px;
            }
        }
    }
    .action-hint {
        @include display-flex-center;
        width: 100%;
        text-transform: uppercase;
    }
    .button-container {
        @include display-flex-center;
        width: 100%;
        padding-top: 5px;
        box-sizing: border-box;
    }
}

.action-list {
    @include display-flex-center;
    width: 100%;
    margin-bottom: 14px;
}

.acttion-desc-container{
    flex-direction: row;
}