@import 'styles/mixins.scss';

.container {
    width: 180px;
    border: 0.5px solid #FB7A7F;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding-bottom: 6px;
    background: white;
    margin-right: 10px;
}

.name-container {
    border-radius: 10px;
    padding: 6px 9px;
    border-bottom: 0.5px solid #FB7A7F;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.category-container {
    padding: 8px 9px 0 9px;
    box-sizing: border-box;
    span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        div:first-child {
            width: 30px;
        }
    }
}

.name{
    margin-left:7px;
}

.user{
    @include display-flex-space-between;
    margin-top: 5px;
    margin-bottom: 3px;
    span {
        @include display-flex-center
    }
}

.ProgressBar{
    @include display-flex-start;
    width: 100%;
    height: 12px;
    bottom: 0;
}

.happiness{
    display: flex;
    align-items: center;
    background: linear-gradient(171.46deg, #FB7286 2.67%, #F79F58 100%);
	-webkit-background-clip: text;
	background-clip: text;
  	-webkit-text-fill-color: transparent;
}

.score{
    margin-left: 22px;
    margin-top: 7px;
}
.valIcon{
    margin-right: 5px;
    width: 26px;
    height: 20px;
}

.value{
    @include display-flex;
    margin-top:5px;
}

.val-percentage{
    text-align: center;
}

.image-size{
    width:24px;
    height:24px;
}
.value-changes {
    opacity: 0.8;
    z-index: 200;
    @include display-flex-center;
    .day-icon {
        margin: 0 4px;
    }
}