@import 'styles/mixins.scss';

.container {
    @include display-flex-space-between;
    max-width: 840px;
    max-height: 110px;
    margin:-25px auto 0 auto;
    border-radius: 55px;
    padding: 35px 12px 0 12px;
    box-sizing: border-box;
    z-index: 1;
    position: relative;
    .helper-textbox {
        position: absolute;
        top: 110px;
        width: 100%;
        .arrow {
            width: 100%;
            @include display-flex-center;
        }
        .text {
            width: 100%;
            margin-top: 15px;
            @include display-flex-center;
        }
    }
}

.domain-container {
    // max-width: 125px;
    padding: 0px 2px;
    margin-bottom: 10px;
    box-sizing: border-box;
    cursor: pointer;
    // remove later
    // overflow: hidden;
    // max-width: 80px;
}

.domain-name {
    @include display-flex;
    padding: 0 5px;
    box-sizing: border-box;
    text-align: center;
}