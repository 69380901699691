.item {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 20px 9px;
    cursor: pointer;
}
.tooltip-container-right{
    min-width: 82px;
    height: 32px;
    position: absolute;
    top: 0px;
    left: 32px;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tooltip-content{
    width: calc(100% - 7px);
    height: 27px;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}