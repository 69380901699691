@import 'styles/mixins.scss';

.container {
    width: 100%;
    height: 100%;
    position: relative;
    .progress {
        height: 100%;
    }
}

.progress-bar-value {
    @include display-flex-center;
    // width: 14px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 6px;
}

.progress-bar-icon {
    @include display-flex-center;
    width: 14px;
    height: 100%;
    position: absolute;
    left: 6px;
    top: 0px;
    img {
        @include display-flex-center;
        width: 10px;
        height: 10px
    }
}