@import 'styles/mixins.scss';

.containerhead{
  @include display-flex-column;
  position: relative;
  width: 57%;
  height: 99%;
  // left: 561px;
  // top: 68px;
  margin-left: 6px;
  background: #FFFFFF;
  border: 1px solid #F8985F;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
  border-radius: 19px;
}
.container {
  height: 100%;
  width: 100%;
    padding-left: 30px;
    box-sizing: border-box;

}
.actorhistoryheader {
  @include display-flex;
  width: 100%;
  margin-top: 21px;
}
.actorHistoryGraphContainer {
  @include display-flex;
  margin-top: 13px;
  width: 100%;
  height: 30%;
}
.actorHistoryGraph {
  width: 50%;
  height: 100%;
}
.actorHistoryGraphDesc {
  @include display-flex-center;
  width: 40%;
  height: 100%;
  margin-left: 16px;
}
.actorHistoryContainer {
  @include display-flex-column;
  width: 100%;
  scroll-behavior: auto;
  margin-top: 2%;
}
.endLine {
  @include display-flex-center;
  width: 90%;
  margin-right: 3%;
  margin-top: 0%;
  opacity: 0.5;
  border: 1px solid #FFE2CE;
}
.impact-circle {
  width: 19.95px;
  height: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-left: 4px;
}
.big-impact-circle {
  width: 24.94px;
  height: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-left: 4px;
  margin-top: -3px;
}
.impact-visualization {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.impacts-desc {
  opacity: 0.5
}
.impact-circle-container {
  display: flex;
  margin-left: 15px;
}
.history-component {
  @include display-flex-column;
  margin-top: 20px;
}
.history-header {
  @include display-flex;
}
.action-impacts-container {
  @include display-flex-column;
  width: 557px;
  border-bottom-style: none;
  border-top-style: none;
  overflow-y: auto;
  height: 160px;
  overflow-x: hidden;
}
.header-container {
  @include display-flex;
  background: linear-gradient(169.56deg, #FB7286 2.67%, #F79F58 100%);;
  width: 557px;
  height: 29px;
  //opacity: 0.06;
}
.action-header {
  @include display-flex;
  align-items: center;
  width: 218px;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.9;
  padding-left: 10px;
}
.impact-header {
  @include display-flex;
  padding-left: 32px;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.9;
  border-radius: 10px;
}

.day-header {
  @include display-flex;
  padding-left: 136px;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.9;
  border-radius: 10px;
}
.single-action-impact-container {
  @include display-flex;
  height: 34px;
  border-bottom: 2px dashed #EBEBEB;
  border-left: 2px dashed #EBEBEB;
  border-right: 2px dashed #EBEBEB;
}
.single-action-container {
  width: 228px;
  padding-left: 10px;
  padding-top: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #031A20;
  opacity: 0.9;
}
.single-impact-container {
  @include display-flex;
  width: 327px;
  border-left: 2px dashed #EBEBEB;
}
.happiness-value {
  @include display-flex;
  width: 100px;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #031A20;
  opacity: 0.9;
}
.happiness-impact {
  @include display-flex;
  padding-left: 10px;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #031A20;
  opacity: 0.9;
}

.day {
  @include display-flex;
  padding-left: 45px;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #031A20;
  opacity: 0.9;
}
.change-image {
  object-fit: contain;
  width: 20px;
}
