@import 'styles/mixins.scss';

 .container1{
     width: 100%;
	// height: 379px;
    // max-height: 379px;
 }
 .container2{
     width: 100%;
    // height: 459px;
    // max-height: 460px;
 }
.container{
    @include display-flex-center;
    z-index: 2;
	margin: 0 auto;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	top: 0;
	left: 0;
    -webkit-animation: fadeIn 4s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 4s; /* Firefox < 16 */
        -ms-animation: fadeIn 4s; /* Internet Explorer */
         -o-animation: fadeIn 4s; /* Opera < 12.1 */
            animation: fadeIn 4s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(300px);
    } 
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

// /* Firefox < 16 */
// @-moz-keyframes fadein {
//     from { opacity: 0; }
//     to   { opacity: 1; }
// }

// /* Safari, Chrome and Opera > 12.1 */
// @-webkit-keyframes fadein {
//     from { opacity: 0; }
//     to   { opacity: 1; }
// }

// /* Internet Explorer */
// @-ms-keyframes fadein {
//     from { opacity: 0; }
//     to   { opacity: 1; }
// }

.headseperator{
	position: absolute;
    width: 100%;
    height: 5px;
    top: 1px;
    box-sizing: border-box;
}

.top-metrics-container{
	@include display-flex-start
	// padding-right: 45px
	// margin-right: 18px
}

.image-container{
	// @include display-flex-center;	
	width: 129px;
	height: 136.98px;
	// padding: 20px 0
	box-sizing: border-box;
	top: 140px;
    left: 27px;
    position: absolute;
}

.imageClose1{
    height: 43px;
    position: absolute;
    right: 0px;
    top: -47px;
    cursor: pointer;
}   

.imageClose2{
    height: 43px;
    position: absolute;
    right: 0px;
    top: -47px;
    cursor: pointer;
}

.response-container{
    width: 85%;
    min-width: 1280px;
    height: 100%;
    padding: 35px 40px 30px 110px;
    box-sizing: border-box;
}

.person-name{
	width: 80%;
	height: 100%;
	padding: 0px 30px 20px 15px;
	box-sizing: border-box;
}

.close-container{
	position: absolute;
	top: 15px;
	right: 15px;
	width: 25px;
	height: 25px;
}

.delayed-impact{
	position: relative;
	text-align: center;
}

.centered{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 97px;
}

.action-response-icon{
	margin: 0
}
.response-text {
    position: absolute;
    bottom: 140px;
    left: -50px;
    font-size: 50px;
    transform: rotate(270deg);
}