@import 'styles/mixins.scss';

.container{
	width: 1084px;
	height: 511px;
    box-sizing: border-box;
    @include display-flex;
    z-index: 2;
}

.header-container{
	@include display-flex-center;
	width: 100%;
	height: 46px;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	z-index: 2;
	position: relative;
	top: 0;
	left: 0;
}

.close-icon{    
    margin-left: -6px;
	cursor: pointer;
}

.content{
	width: 100%;
	height: 440px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: auto;
}

.intro-body {
    height: calc(100% - 52px);
    max-width: 800px;
    padding: 25px 0;
    box-sizing: border-box;
    margin: 0 auto;
}

.image-container{
    @include display-flex-end;
    position: absolute;
    width: 174.2px;
    height: 184.98px;
    bottom: 10px;
    right: 5px;
}

.heading-icon{
	margin-right: 10px
}