@import 'styles/mixins.scss';

.container {
    width: 170px;
    height: 80px;
    margin: 0 10px;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    .select-icon {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        right: 10px;
    }
    .action-name {
        @include display-flex-center;
        width: 100%;
        height: 55px;
        padding: 23px 0 16px;
        text-align: center;
        box-sizing: border-box;
    }
    .hr-line {
        width: 100%;
        height: 1px;
    }
    .details {
        @include display-flex-space-evenly;
        width: 100%;
        padding: 6px 0;
        box-sizing: border-box;
        .action-icon {
            @include display-flex-center;
            width: 12px;
            height: 12px;
        }
        .vr-line {
            width: 1px;
            height: 14px;
        }
        .day-info {
            @include display-flex-center;
        }
        .budget-info{
            @include display-flex-center;
        }
    }
    .details-disabled {
        width: 100%;
        .action-icon-disabled {
            @include display-flex-center;
            width: 100%;
            position: relative;
            img {
                filter: blur(3px);
                -webkit-filter: blur(3px);
                position: absolute;
                left: 3px;
                top: 0;
                z-index: 1;
            }
        }
        .text-action-disabled {
            @include display-flex-center;
            margin-top: 2px;
            z-index: 2;
            position: relative;
            height: 0px;
            text-align: center;
        }
    }
}

.details-text {
    margin-left: 5px;
}
