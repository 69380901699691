@import 'styles/mixins.scss';

.container {
    @include display-flex-center;
    z-index: 10000;
    padding: 5px;
    box-sizing: border-box;
    height: 100%;
    border-radius: 35px;
    width: 120px;
    img {
        margin-left: 10px;
        cursor: pointer;
    }
    .username {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    // .username:hover:before {
    //     content: attr(data-tooltip);
    //     position: absolute;
    //     padding: 5px 10px;
    //     margin: -5px 0 0 -30px;
    //     background: #F79F58;
    //     color: white;
    //     border-radius: 3px;
    // }
}

.item {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 20px 9px;
    cursor: pointer;
}
.tooltip-container-right{
    min-width: 82px;
    height: 32px;
    position: absolute;
    top: 40px;
    right: 16px;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tooltip-content{
    width: calc(100% - 7px);
    height: 27px;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}