@import 'styles/mixins.scss';

.containerhead{
  @include display-flex-column;
  position: relative;
  width: 30%;
  height: 100%;
  // left: 561px;
  // top: 68px;
  background: #FFFFFF;
  border: 1px solid #F8985F;
  padding: 10px 18px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
  border-radius: 19px;
}
.container {
  height: 100%;
  width: 100%;
    padding-left: 30px;
    box-sizing: border-box;

}
.actorComponent{
  @include display-flex-center;
  justify-content: flex-start;
  width: 100%;
}
.actorDescComponent{
  width: 95%;
  padding-left: 17px;
  padding-right: 7px;
  height: 35%;
}
.actorDescription{
  opacity: 0.8;
  line-height: 21px;
  display: flex;
  align-items: center;
}
.actorGraphComponent{
  margin-top: 20px;
  @include display-flex-center;
  width: 95%;
  height: 40%;
}
.actorGraph{
  width: 65%;
  height: 100%;
}
.actorGraphDesc{
  margin-left: 20px;
  width: 35%;
}
.actor-image-component {
  width: 55px;
  min-width: 55px;
  height: 90px;
  border-radius: 5px;
}
.actor-component-desc {
  @include display-flex-column;
  margin-left: 18px;
}
.actor-name {
  margin-top: 1px;
}
.actor-designation {
  opacity: 0.5
}
.aboutActorComponent {
  opacity: 0.4;
}
.progress-bar-component {
  margin-top: 5px;
  width: 140px;
  height: 14px;
}
.actorhistoryheader {
  @include display-flex;
  width: 100%;
  margin-top: 21px;
}
.actorHistoryGraphContainer {
  margin-top: 13px;
  width: 100%;
}
.actorHistoryGraph {
    width: 100%;
    height: 160px;
    margin-top: 9px;
}
.actorHistoryGraphDesc {
  display: inline;;
  width: 100%;
  span {
    font-weight: bold;
  }
}
.selectMember{
  margin-left: 38px;
  margin-right: 38px;
  margin-top: 91px;
}