@import 'styles/mixins.scss';

.container {
    width: 150px;
    height: 150px;
    border-radius: 3px;
    margin: 7px;
    position: relative;
    .card-container {
        width: 146px;
        height: 146px; 
        background: white;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        cursor: default;
        position: absolute;
        top: 2px;
        left: 2px;
        .image-container {
            margin: 14px auto 2px;
            width: 52px;
            height: 52px;
            position: relative;
            .actor-image {
                width: 100%;
                height: 100%;
            }
            .actor-emoticon {
                position: absolute;
                top: 1px;
                left: -7px;
                width: 24px;
                height: 24px;
            }
        }
        .select-icon {
            width: 22px;
            height: 22px;
            border-radius: 50%;
        }
        .name {
            @include display-flex-center;
            text-align: center;
            padding: 0 10px;
            box-sizing: border-box;
        }
        .designation {
            @include display-flex-center;
            text-align: center;
            padding: 0 10px;
            box-sizing: border-box;
        }
        .view-profile-button {
            background: white;
            border-radius: 10px;
        }
        .actor-disabled {
            padding: 5px;
            box-sizing: border-box;
        }
    }
}

.progress-bar {
    width: 100%;
    height: 12px;
    bottom: 0;
    position: absolute;
}

.radio-btn-selection-area{
    @include display-flex-center;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -2px;
    right: 0px;
}

.view-profile-button {
    font-family: Nunito;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 18px !important;
    margin: auto;
    width: 92%;
    position: absolute;
    bottom: 15px;
    left: 0.4vw;
}

.locked-icon {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 30px;
    height: 28px;
}