@import 'styles/mixins.scss';

.container {
    @include display-flex-start;
    width: 220px;
    height: 28px;
    background: #FFFFFF;
    border: 1.5px solid #E8E8E8;
    border-radius: 18px;
    padding: 3px 17px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
}

.current-option {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-menu {
    position: absolute;
    width: 220px;
    top: 30px;
    left: 0;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: auto;
    div {
        @include display-flex-start;
        padding: 1px 20px;
        box-sizing: border-box;
        cursor: pointer;
        min-height: 30px;
    }
    div:hover {
        background-image: linear-gradient(102.64deg, #00D7FF 4.65%, #7DEEB9 97.99%);
    }
    img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
        align-items: end
    }
}