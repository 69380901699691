@import 'styles/mixins.scss';

.external-container {
    width: 100%;
    height: 100%;
    .container {
        width: 100%;
        height: calc(100% - 72px);
        overflow-y: auto;
        .intro-body {
            @include display-flex-center;
            padding: calc(50vh - 240px) 0;
            margin: 0 auto;
        }
    }
    .intro-footer {
        @include display-flex-center;
        width: 100%;
        height: 72px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        position: relative;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}