@import 'styles/mixins.scss';

.container {
	width: 540px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 25px 35px;
    box-sizing: border-box;
    border: 1px solid #F8985F;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    z-index: 2;
    .image {
        @include display-flex-center;
    }
    .title {
        @include display-flex-center;
        margin-top: 10px;
    }
    .hrline {
        width: 100%;
        margin-top: 22px;
        opacity: 0.5;
    }
    .text {
        @include display-flex-center;
        margin-top: 22px;
    }
    .button-container {
        @include display-flex-space-around;
        margin-top: 35px;
    }
}

.cancel-button{
    @include display-flex-center;
    min-width: 212px;
    height: 35px;
    padding: 9px 21px;
    box-sizing: border-box;
    border-radius: 25px;
    cursor: pointer;
    z-index: 5;
    color: #00D7FF;
    // background: linear-gradient(144.43deg, #00D7FF 4.65%, #7DEEB9 97.99%);
	// -webkit-background-clip: text;
	// background-clip: text;
  	// -webkit-text-fill-color: transparent;
}