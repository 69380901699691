@import 'styles/mixins.scss';

.title{
	top: '276px',
}

.actorInfo{
	@include display-flex;	
}

.response-message{
	// width: 963px;
	box-sizing: border-box;
    margin: 16px 0px 24px 0;
}

.response-message-ind{
    width: calc(100% - 200px);
	box-sizing: border-box;
}

.image-container{	
    top: -50px;
    left: -5px;
    position: absolute;
}

.delayed-impact{
	position: relative;
    text-align: center;
    img {
        width: 164px;
        height: 35px;
    }
}

.centered{
	position: absolute;
	top: 8px;
    left: 14px;
}

.button-container {
    width: 212px;
}