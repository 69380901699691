@import 'styles/mixins.scss';

.individual{
    @include display-flex-center;
    margin-bottom: 5px;
}

.actorImage{
    width: 26px;
    height: 26px;
    margin-left: 10px;
    margin-right: 12.5px;
}

.individualScore{
    margin-left:15px;
    width: 42px; 
}

.actor{
    width: 110px; 
}